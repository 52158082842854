import FormatDate from '../FormatDate/FormatDate';
import {getReactiveContent, getStripHtmlTagsStr, getStyledTitle} from "@/lib/utils/helpers";
import classNames from 'classnames';
import PropTypes from "prop-types";
import Dropdown from "@/components/Dropdown/Dropdown";
import ContentBlock from "@/components/ContentBlock/ContentBlock";
import PostActions from "@/components/PostActions/PostActions";
import {gql} from "@apollo/client";
import IconClock from "@/lib/assets/images/clock.svg";
import PostAuthorImage from "@/components/PostAuthorImage/PostAuthorImage";
import PostAuthor from "@/components/PostAuthor/PostAuthor";
import Link from "next/link";

export default function PostInfo({className, post}) {
  const {
    content,
    date,
    modified,
    momPostGenre: postGenre,
    // momAuthorsHtml: authors, // No longer used
    momReviewersHtml: reviewers,
    momRawExcerpt: rawExcerpt,
    momReadingTime: readingTime,
    momPostDisclaimer: postDisclaimer,
    momPublicAuthors: publicAuthors
  } = post;

  const {
    postDisableLastUpdated: disableLastUpdated,
  } = post?.postSettings || {};

  if (!date && !authors) {
    return null;
  }

  // Check if same date and modified (date only)
  const hideLastUpdate = disableLastUpdated || (new Date(date).toLocaleDateString('en-AU') === new Date(modified).toLocaleDateString('en-AU'));

  // Author
  const {
    fullName,
    avatarUrlSmall,
    uri,
  } = publicAuthors[0];

  return (
    <>
      <div className={classNames(className)}>
        <div className="flex">
          <Link href={uri} prefetch={false} className={`mr-3`}>
            <PostAuthorImage size={32} alt={fullName} avatarUrl={avatarUrlSmall} className={`mt-1`}/>
          </Link>

          <div>
            <strong>
              {fullName && (
                <>By <Link href={uri} prefetch={false}>{fullName}</Link></>
              )}{postGenre && (<> - {postGenre}</>)}
            </strong>

            {date && (
              <p className={classNames('mt-2 font-serif text-[15px]')}>
                Published: <time dateTime={date} className={`italic`}><FormatDate date={date}/></time>
                {
                  !hideLastUpdate ?
                    <span className={`block md:inline`}><span
                      className={`mx-2 hidden md:inline`}>|</span>Last Updated:&nbsp;
                      <time dateTime={modified} className={`italic`}><FormatDate date={modified}/></time>
                    </span> :
                    ''
                }
              </p>
            )}

            {reviewers && (<p className={classNames('mt-2.5 mb-0')}>{getReactiveContent({htmlString: reviewers})}</p>)}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-10 gap-4 mt-6 mb-7">
          <PostActions className={`md:col-span-6 text-left`}/>

          {content.includes('wp-block-yoast-seo-table-of-contents') && (
            <Dropdown heading={'Table of contents'} className={`md:col-span-4`}>
              <ContentBlock htmlString={content} classToExtract={'wp-block-yoast-seo-table-of-contents'}/>
            </Dropdown>
          )}
        </div>

        {readingTime && (
          <p className={`font-bold mb-7`}>
            <IconClock className={`inline-block mr-3`}/> Readtime: {readingTime}
          </p>
        )}

        {rawExcerpt && (
          <>
            <p className={`font-sans text-xxs font-bold uppercase tracking-widest`}>The Lowdown:</p>
            <p className={`lg:text-[23px] font-serif`}>
              {getStyledTitle({
                title: getStripHtmlTagsStr(rawExcerpt),
                italicLast: false,
                delimiters: [':', '  ', "\n"]
              })}
            </p>
          </>
        )}

        {postDisclaimer && (
          <div className={classNames(
            `mom-border border-y-2 py-3 mt-4`,
            `[&_p]:!text-left [&_p]:!leading-normal`,
            `[&_*]:text-xs [&_*]:!tracking-normal [&_*]:!text-black dark:[&_*]:!text-white`,
          )}>
            {getReactiveContent({htmlString: postDisclaimer})}
          </div>
        )}
      </div>
    </>
  );
}

PostInfo.fragments = {
  key: 'PostInfoFragment',
  entry: gql`
    ${PostAuthor.fragments.entry}
    fragment PostInfoFragment on Post {
      date
      modified
      momPostGenre
      momReviewersHtml
      momRawExcerpt
      momReadingTime
      momPostDisclaimer
      momPublicAuthors {
        ...${PostAuthor.fragments.key}
      }
      postSettings {
        postDisableLastUpdated
      }
    }
  `
};

PostInfo.propTypes = {
  date: PropTypes.string,
  modified: PropTypes.string,
  authors: PropTypes.string,
  reviewers: PropTypes.string,
  postGenre: PropTypes.string,
  className: PropTypes.string
};
