import classNames from 'classnames';
import React from "react";

import {usePrimaryMenuStore, useStickyHeaderStore} from "@/lib/stores/layout";
import Container from "@/components/Container/Container";
/* import {PRIMARY_NAV_ID, PRIMARY_NAV_TOGGLE_ID} from "@/lib/utils/constants";
import Button from "@/components/Button/Button";
import IconHamburger from '@/lib/assets/images/mobile-menu.svg';
import IconClose from '@/lib/assets/images/cross-white.svg'; */
import NavigationMenu from "@/components/NavigationMenu/NavigationMenu";
import HamburgerButton from '@/components/Button/HamburgerButton';

export default function HeaderBottom({primaryMenu}) {
  // const togglePrimaryMenu = usePrimaryMenuStore(state => state.toggle);
  const isPrimaryMenuVisible = usePrimaryMenuStore(state => state.visible);
  const isHeaderSticky = useStickyHeaderStore(state => state.sticky);

  return (
    <Container
      edgePadding={false}
      className={classNames(
        `flex flex-nowrap justify-start lg:justify-center bg-white dark:bg-black relative z-10`,
        `mom-border border-b-2 transition-[padding]`,
        {'lg:pb-3 xl:pb-5': !isHeaderSticky},
      )}
    >
      <div className={classNames(
        `w-full overflow-x-auto lg:overflow-visible pl-2 lg:pl-0 transition-opacity`,
        'mom-scrollbar-hide max-lg:[&_li:last-child]:pr-8',
        {'opacity-0 lg:opacity-20 pointer-events-none': isPrimaryMenuVisible},
      )}>
        <NavigationMenu
          menuItems={primaryMenu}
          variant={'dropdown'}
        />
      </div>

      <div className={classNames(
        'relative flex items-center justify-end shrink-0',
        'w-14 lg:hidden',
      )}>
        <HamburgerButton className={'lg:hidden'} />
        {/* <Button
          id={PRIMARY_NAV_TOGGLE_ID}
          variant={'icon'}
          onClick={() => togglePrimaryMenu()}
          type="button"
          aria-label="Toggle navigation"
          aria-controls={PRIMARY_NAV_ID}
          aria-expanded={isPrimaryMenuVisible}
          tabIndex={-1}
          className={`px-4 [&_svg]:w-5 [&_svg]:mr-0 !text-black dark:!text-white [&_path]:!stroke-current`}
        >
          {isPrimaryMenuVisible ? <IconClose/> : <IconHamburger/>}
        </Button> */}

        <aside className={`mom-gradient-block`}/>
      </div>
    </Container>
  );
}
