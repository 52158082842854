import React from 'react';
import Button from '@/components/Button/Button';
import IconHamburger from '@/lib/assets/images/mobile-menu.svg';
import IconClose from '@/lib/assets/images/cross-white.svg';
import classNames from 'classnames';
import {useSearchOverlayStore, useStickyHeaderStore, useStickyNewsletterStore, usePrimaryMenuStore} from "@/lib/stores/layout";

export default function HamburgerButton({ className, stickyClose = false }) {
  const togglePrimaryMenu = usePrimaryMenuStore(state => state.toggle);
  const isPrimaryMenuVisible = usePrimaryMenuStore(state => state.visible);
 
  return (
    <Button
      variant={'icon'}
      onClick={togglePrimaryMenu}
      type="button"
      aria-label="Toggle navigation"
      aria-expanded={isPrimaryMenuVisible}
      tabIndex={-1}
      className={classNames(
        'px-4 [&_svg]:w-5 [&_svg]:mr-0 !text-black dark:!text-white [&_path]:!stroke-current',
        className
      )}
    >
      {(isPrimaryMenuVisible || stickyClose) ? <IconClose /> : <IconHamburger />}
    </Button>
  );
} 