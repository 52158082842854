import classNames from 'classnames';
import PropTypes from "prop-types";
import {Fragment, useRef} from "react";
import dynamic from "next/dynamic";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import PostCard from "@/components/PostCard/PostCard";
import {chunkArray} from "@/lib/utils/helpers";
import {CARD_SIZE} from "@/lib/utils/constants";
// import {useDraggableScroll} from "@/lib/utils/hooks";

const AdSlotGridInline = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.GridInline));

export default function PostsGrid(
  {
    adObjectType = 'archive',
    className,
    columnsPerRow = 4,
    isFeatured = false,
    gridClassName = 'grid-cols-2 xl:grid-cols-4',
    gridItemClassName = '',
    posts,
    prioritizeFirstRow = false,
    showAd = false,
    scrollable = false,
    scrollableOffXl = false,
    variant = 'default',
  }
) {
  const chunkedPosts = chunkArray(posts, 1 === columnsPerRow ? posts.length : columnsPerRow);
  const isSmallVariant = variant === 'small';
  const ref = useRef(null);
  // const draggableEvents = useDraggableScroll(ref);

  const content = (
    <>
      {chunkedPosts.map((chunk, rowIndex) => (
        <Fragment key={rowIndex}>
          <div
            className={classNames(
              gridClassName,
              'gap-x-4 xl:gap-x-5',
              {'inline-flex': scrollable},
              {'grid': !scrollable},
              {'xl:grid': scrollable && scrollableOffXl},
            )}
          >
            {chunk.map((post, index) => (
              /* High priority loading for images in first row only */
              <PostCard
                featuredImagePriority={rowIndex < 1 && prioritizeFirstRow}
                className={classNames(
                  {'shrink-0 w-[280px]': scrollable},
                  {'xl:shrink xl:w-auto xl:mb-6': scrollable && scrollableOffXl},
                  {'mr-4': scrollable && (index === chunk.length - 1)},
                  {'mb-5': !scrollable},
                  {'lg:mb-6': !scrollable && isSmallVariant},
                  {'lg:mb-10': !scrollable && !isSmallVariant},
                  gridItemClassName,
                )}
                key={post.databaseId}
                variant={variant}
                isFeatured={isFeatured}
                {...post}
              />
            ))}
          </div>

          {(showAd && rowIndex < chunkedPosts.length - 1) && (
            <AdSlotGridInline adRowIndex={rowIndex} objectType={adObjectType}/>
          )}
        </Fragment>
      ))}
    </>
  );

  if (!scrollable) {
    return (
      <div className={className}>
        {content}
      </div>
    );
  }

  return (
    <SimpleBar
      ref={ref}
      /* {...draggableEvents} */
      className={classNames(
        className,
        'select-none',
        /* 'cursor-grab active:cursor-grabbing active:[&_a]:!cursor-grabbing', */
        {'xl:cursor-default xl:active:cursor-default': scrollableOffXl},
        {'xl:overflow-x-visible': scrollableOffXl},
        'mom-scrollbar-custom',
      )}
      autoHide={false}
      direction="rtl"
    >
      {content}
    </SimpleBar>
  );
};

PostsGrid.propTypes = {
  adObjectType: PropTypes.string,
  className: PropTypes.string,
  columnsPerRow: PropTypes.number,
  isFeatured: PropTypes.bool,
  gridClassName: PropTypes.string,
  posts: PropTypes.array.isRequired,
  prioritizeFirstRow: PropTypes.bool,
  showAd: PropTypes.bool,
  scrollable: PropTypes.bool,
  variant: PropTypes.oneOf(CARD_SIZE)
};
