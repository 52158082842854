// import {useRouter} from "next/router";
import {Suspense} from "react";
import {gql} from "@apollo/client";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as constants from "@/lib/utils/constants";
import {LAST_ROW_AD_INDEX, URI_TEAM} from "@/lib/utils/constants";
import {FooterFragment, GlobalFragment} from "@/lib/fragments";
import PostAuthorFull from "@/components/PostAuthor/PostAuthorFull";
import Container from "@/components/Container/Container";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Main from "@/components/Main/Main";
import PostsGrid from "@/components/PostsGrid/PostsGrid";
import SEO from "@/components/SEO/SEO";
import NavigationMenu from "@/components/NavigationMenu/NavigationMenu";
import PostCard from "@/components/PostCard/PostCard";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import EntryHeader from '@/components/EntryHeader/EntryHeader';
import InjectBanner from "@/components/InjectBanner/InjectBanner";
import Loading from "@/components/Loading/Loading";
import Heading from "@/components/Heading/Heading";
import HeaderSubNav from "@/components/Header/HeaderSubNav";
import TermLinks from "@/components/TermLinks/TermLinks";
import Newsletter from "@/components/Newsletter/Newsletter";
import { CloudSnow } from "lucide-react";

const Pagination = dynamic(() => import('@/components//Pagination/Pagination').then((mod) => mod.default));
const AdSlotFooterDesktop = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterDesktop));
const AdSlotFooterSticky = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterSticky));
const AdSlotOneByOne = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OneByOne));
const AdSlotOutOfSpace = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OutOfSpace));

export default function ArchivePosts(props) {
  // const router = useRouter();
  const {data} = props;
  const {paged} = props?.__TEMPLATE_VARIABLES__ || props?.__PAGE_VARIABLES__ || {};
  const {uri, slug, __typename: archiveType} = props?.__SEED_NODE__ || {};

  let overrideTitle;
  let overrideDescription;
  let overrideBreadcrumbs;
  let iconsHtml;
  let showAd = true;
  let isReviewer = false;
  let node;
  let popularPosts;
  let stickyPosts;
  let firstGroupCount = 5;

  // Node selection based on archiveType (SEED NODE's __typename)
  switch (archiveType) {
    case 'Category':
      node = data?.node;
      // iconsHtml = node?.momCategoryIconsHtml;
      showAd = node?.momCategoryShowAd;
      popularPosts = data?.momPopularPosts;
      stickyPosts = node?.momCategoryStickyPosts;
      break;

    case 'Tag':
      node = data?.node;
      // iconsHtml = node?.momTagIconsHtml;
      showAd = node?.momTagShowAd;
      popularPosts = data?.momPopularPosts;
      break;

    /*case 'Reviewer':
      node = data?.momReviewer;
      overrideTitle = `${node.momPublicAuthor.fullName}, Reviewer at Man of Many`;
      overrideBreadcrumbs = [
        {
          url: '/',
          text: 'Home'
        },
        {
          url: URI_TEAM,
          text: 'Man of Many Reviewers'
        }
      ];
      break;*/

    case 'User':
      node = data?.user;
      isReviewer = node.momPublicAuthor.isReviewer;

      if (isReviewer) {
        overrideTitle = `${node.momPublicAuthor.fullName}, Reviewer at Man of Many`;
        overrideBreadcrumbs = [
          {
            url: '/',
            text: 'Home'
          },
          {
            url: URI_TEAM,
            text: 'Man of Many Reviewers'
          }
        ];
      } else {
        overrideBreadcrumbs = [
          {
            url: '/',
            text: 'Home'
          },
          {
            url: URI_TEAM,
            text: 'Man of Many Team'
          }
        ];
      }
      break;

    case 'ContentType': // For /blog
      node = data?.nodeByUri?.momPageForPosts;
      overrideTitle = 'Latest Posts';
      break;

    case 'Page': // For /news
      switch (slug) {
        case 'news':
          overrideTitle = 'News';
          break;
      }

      node = data?.page;
      overrideDescription = node?.pageSettings?.pageBrief;
      break;

    default:
      node = data?.node;
  }

  // Primary data
  const {name, description, seo} = node || {};
  const {edges, pageInfo: {offsetPagination: {total, hasMore, hasPrevious}}} = data.posts || {};
  const postNodes = edges.map(({node}) => node);
  const {
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    momSubNavHeader: subNavHtml,
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    popularTags = [],
  } = data || {};

  const isFirstPage = paged <= 1;
  const isUserArchive = archiveType === 'User';
  const shouldFeatureFirstGroup = !isUserArchive && isFirstPage && postNodes.length > 0;

  // Prepend sticky posts
  if (shouldFeatureFirstGroup && stickyPosts && stickyPosts?.length > 0) {
    firstGroupCount += stickyPosts.length;

    // Attempt to remove sticky posts from the postNodes array
    stickyPosts.forEach(stickyPost => {
      const index = postNodes.findIndex(post => post.databaseId === stickyPost.databaseId);
      if (index !== -1) {
        postNodes.splice(index, 1);
        firstGroupCount--;
      }
    });

    postNodes.unshift(...stickyPosts);
  }

  // Split first group
  const firstGroup = postNodes.slice(0, firstGroupCount);
  const firstInGroup = firstGroup[0]; // TODO: replace this with better logic
  const restOfGroup = firstGroup.slice(1);
  const restOfPostNodes = postNodes.slice(firstGroupCount);

  // Refine posts list, in case first page has more than constants.POSTS_PER_PAGE (eg: Author pages)
  if (isFirstPage && postNodes.length > constants.POSTS_PER_PAGE) {
    postNodes.pop(); // The cut off post will be found on the next page
  }

  return (
    <>
      <SEO {...{seo, title: overrideTitle, paged, hasMore, hasPrevious}} url={uri}/>

      <Header {...{primaryMenu, subMenuA, subMenuB, siteSettings, popularTags}}/>

      <Main>
        <Suspense fallback={<Loading/>}>
          <Container>
            <InjectBanner
              settings={siteSettings}
              positionDesktop={4}
              positionMobile={3}
              style={{marginTop: '1rem'}}
            />
          </Container>

          {isUserArchive ? (
            <Container>
              <PostAuthorFull publicAuthor={node?.momPublicAuthor}/>
            </Container>
          ) : (
            <>
              <Container>
                <Breadcrumbs list={overrideBreadcrumbs || seo?.breadcrumbs}/>

                <EntryHeader
                  title={`${overrideTitle || name}${!isFirstPage ? ` - Page ${paged}` : ''}`}
                  description={overrideDescription || description}
                />
              </Container>
              {/*{iconsHtml && <IconsGrid html={iconsHtml}/>}*/}
              {subNavHtml && <HeaderSubNav html={subNavHtml} isSticky={true}/>}
            </>
          )}

          <Container className={classNames(
            `mom-gap`,
            {'!pb-4': shouldFeatureFirstGroup},
            {'mom-border border-t-2': !subNavHtml}
          )}>
            {shouldFeatureFirstGroup && (
              <>
                <div className={`flex flex-nowrap justify-between items-center mb-4 gap-5`}>
                  <Heading level={`h2`} className={`mom-section-heading`}>
                    {overrideTitle || `The ${restOfGroup.length + 1} Latest ${name} Articles`}
                  </Heading>
                  {/*<Link className={`mom-btn mom-btn--sm shrink-0`} href={constants.URI_BLOG}>See All</Link>*/}
                </div>

                <div className={`grid grid-rows-1 grid-cols-1 xl:grid-cols-2 gap-x-4 xl:gap-x-5`}>
                  <PostCard {...firstInGroup} variant={'large'} className={`mb-5`}/>

                  <PostsGrid
                    posts={restOfGroup}
                    gridClassName={`grid-cols-2`}
                  />
                </div>
              </>
            )}
          </Container>

          <Container className={classNames(
            {'mom-section pb-0': shouldFeatureFirstGroup},
          )}>
            {total > 0 && (
              <Heading level={`h2`} className={`mom-heading`}>
                {isReviewer ? (
                  <>{total} {1 === total ? 'Article' : 'Articles'} Reviewed</>
                ) : (
                  <>{total} {overrideTitle || name} {1 === total ? 'Article' : 'Articles'} Published</>
                )}
              </Heading>
            )}

            <PostsGrid
              posts={shouldFeatureFirstGroup ? restOfPostNodes : postNodes}
              prioritizeFirstRow={true}
              showAd={showAd}
              adObjectType={'archive'}
            />

            {Math.ceil(total / constants.POSTS_PER_PAGE) > 1 && (
              <Pagination {...{total, paged, hasMore, hasPrevious, uri}}/>
            )}

            {popularPosts && popularPosts?.length > 0 && (
              <Container className={`mom-section`} edgePadding={false}>
                <Heading className={`mom-subheading mx-4 xl:mx-0`} level={'h3'}>Popular Stories</Heading>
                <PostsGrid
                  posts={popularPosts}
                  className={`pl-4 xl:pl-container xl:-mx-container`}
                  columnsPerRow={1}
                  variant={'small'}
                  gridClassName={`grid-cols-1`}
                  scrollable={true}
                />
              </Container>
            )}

            <Container edgePadding={false} className={`mom-section pb-0`}>
              <Newsletter showAwards={true} heading={'Want to join  our exclusive community?'} description={''} variant={'inline'}/>
              <InjectBanner
                settings={siteSettings}
                positionDesktop={0}
                positionMobile={4}
                style={{marginTop: '1rem'}}
              />
            </Container>

            {showAd && (
              <AdSlotFooterDesktop
                adRowIndex={LAST_ROW_AD_INDEX} objectType={'archive'}
              />
            )}
          </Container>
        </Suspense>
      </Main>

      <Footer {...{subMenuA, subMenuB, siteSettings}}/>

      {showAd && (
        <>
          <AdSlotFooterSticky objectType={'archive'}/>
          <AdSlotOneByOne objectType={'archive'}/>
          <AdSlotOutOfSpace objectType={'archive'}/>
        </>
      )}
    </>
  );
}

ArchivePosts.propTypes = {
  props: PropTypes.object,
  loading: PropTypes.bool,
  children: PropTypes.node,
  __TEMPLATE_VARIABLES__: PropTypes.object,
  __TEMPLATE_QUERY_DATA__: PropTypes.object,
  __FAUST_QUERIES__: PropTypes.object,
  __APOLLO_STATE__: PropTypes.object,
  __SEED_NODE__: PropTypes.shape({
    __typename: PropTypes.string,
    uri: PropTypes.string,
    id: PropTypes.string,
    databaseId: PropTypes.number,
    isTermNode: PropTypes.bool,
    slug: PropTypes.string,
    taxonomyName: PropTypes.string
  }),
};

ArchivePosts.entry = {
  blog: gql`
    ${FooterFragment}
    ${GlobalFragment}
    ${SEO.fragments.entryPostType}
    ${NavigationMenu.fragments.entry}
    ${PostCard.fragments.entry}
    ${TermLinks.fragments.entryRootTag}
    query GetBlogPage(
      $databaseIdID: ID!
      $offset: Int!
      $postsPerPage: Int!
      $headerLocation: MenuLocationEnum
      $subMenuALocation: MenuLocationEnum
      $subMenuBLocation: MenuLocationEnum
    ) {
      nodeByUri(uri: "${constants.URI_BLOG}") {
        ... on ContentType {
          momPageForPosts {
            title
            seo {
              ...${SEO.fragments.keyPostType}
            }
          }
        }
      }
      posts(where: {offsetPagination: {offset: $offset, size: $postsPerPage}}) {
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
        edges {
          node {
            ...${PostCard.fragments.key}
          }
        }
      }
      momSubNavHeader(objectDatabaseID: $databaseIdID)
      momAdTargeting(objectDatabaseID: $databaseIdID)
      headlessSiteSettings {
        ...GlobalFragment
        ...FooterFragment
      }
      primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
        ...${TermLinks.fragments.keyRootTag}
      }
    }
  `,
  news: gql`
    ${FooterFragment}
    ${GlobalFragment}
    ${SEO.fragments.entryPostType}
    ${NavigationMenu.fragments.entry}
    ${PostCard.fragments.entry}
    ${TermLinks.fragments.entryRootTag}
    query GetNewsPage(
      $databaseIdID: ID!
      $offset: Int!
      $postsPerPage: Int!
      $headerLocation: MenuLocationEnum
      $subMenuALocation: MenuLocationEnum
      $subMenuBLocation: MenuLocationEnum
    ) {
      page(id: $databaseIdID, idType: DATABASE_ID) {
        title
        content
        seo {
          ...${SEO.fragments.keyPostType}
        }
        pageSettings {
          pageBrief
        }
      }
      posts(where: {metaQuery: {metaArray: {compare: EQUAL_TO, key: "post__include_in_core_news", value: "1"}}, offsetPagination: {offset: $offset, size: $postsPerPage}}) {
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
        edges {
          node {
            ...${PostCard.fragments.key}
          }
        }
      }
      momSubNavHeader(objectDatabaseID: $databaseIdID)
      momAdTargeting(objectDatabaseID: $databaseIdID)
      headlessSiteSettings {
        ...GlobalFragment
        ...FooterFragment
      }
      primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
        ...${TermLinks.fragments.keyRootTag}
      }
    }
  `,
  author: gql`
    ${FooterFragment}
    ${GlobalFragment}
    ${SEO.fragments.entryUser}
    ${NavigationMenu.fragments.entry}
    ${PostCard.fragments.entry}
    ${TermLinks.fragments.entryRootTag}
    ${PostAuthorFull.fragments.entry}
    query GetAuthorPage(
      $offset: Int!
      $databaseIdID: ID!
      $databaseIdInt: Int!
      $postsPerPage: Int!
      $headerLocation: MenuLocationEnum
      $subMenuALocation: MenuLocationEnum
      $subMenuBLocation: MenuLocationEnum
    ) {
      user(id: $databaseIdID, idType: DATABASE_ID) {
        momPublicAuthor {
          ...${PostAuthorFull.fragments.key}
        }
        seo {
          ...${SEO.fragments.keyUser}
        }
      }
      posts(where: {author: $databaseIdInt, offsetPagination: {offset: $offset, size: $postsPerPage}}) {
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
        edges {
          node {
            ...${PostCard.fragments.key}
          }
        }
      }
      momSubNavHeader(objectDatabaseID: $databaseIdID)
      momAdTargeting(objectDatabaseID: $databaseIdID)
      headlessSiteSettings {
        ...GlobalFragment
        ...FooterFragment
      }
      primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
        ...${TermLinks.fragments.keyRootTag}
      }
    }
  `,
  /*reviewer: gql`
    ${FooterFragment}
    ${GlobalFragment}
    ${SEO.fragments.entryUser}
    ${NavigationMenu.fragments.entry}
    ${PostCard.fragments.entry}
    ${PostAuthorFull.fragments.entry}
    query GetAuthorPage(
      $offset: Int!
      $databaseIdID: ID!
      $databaseIdInt: Int!
      $postsPerPage: Int!
      $headerLocation: MenuLocationEnum
      $subMenuALocation: MenuLocationEnum
      $subMenuBLocation: MenuLocationEnum
    ) {
      momReviewer(userID: $databaseIdInt) {
        momPublicAuthor {
          ...${PostAuthorFull.fragments.key}
        }
        seo {
          ...${SEO.fragments.keyUser}
        }
      }
      posts(where: {author: $databaseIdInt, offsetPagination: {offset: $offset, size: $postsPerPage}}) {
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
        edges {
          node {
            ...${PostCard.fragments.key}
          }
        }
      }
      momSubNavHeader(objectDatabaseID: $databaseIdID)
      momAdTargeting(objectDatabaseID: $databaseIdID)
      headlessSiteSettings {
        ...GlobalFragment
        ...FooterFragment
      }
      primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
    }
  `,*/
  category: gql`
    ${FooterFragment}
    ${GlobalFragment}
    ${SEO.fragments.entryTaxonomy}
    ${NavigationMenu.fragments.entry}
    ${PostCard.fragments.entry}
    ${TermLinks.fragments.entryRootTag}
    query GetCategoryPage(
      $id: ID!
      $databaseIdID: ID!
      $offset: Int!
      $postsPerPage: Int!
      $headerLocation: MenuLocationEnum
      $subMenuALocation: MenuLocationEnum
      $subMenuBLocation: MenuLocationEnum
    ) {
      node(id: $id) {
        ... on Category {
          name
          description
          momCategoryIconsHtml
          momCategoryShowAd
          momCategoryStickyPosts {
            ...${PostCard.fragments.key}
          }
          seo {
            ...${SEO.fragments.keyTaxonomy}
          }
        }
      }
      posts(where: {categoryIn: [$id], offsetPagination: {offset: $offset, size: $postsPerPage}}) {
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
        edges {
          node {
            ...${PostCard.fragments.key}
          }
        }
      }
      momSubNavHeader(objectDatabaseID: $databaseIdID)
      momAdTargeting(objectDatabaseID: $databaseIdID)
      momPopularPosts {
        ...${PostCard.fragments.key}
      }
      headlessSiteSettings {
        ...GlobalFragment
        ...FooterFragment
      }
      primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
        ...${TermLinks.fragments.keyRootTag}
      }
    }
  `,
  tag: gql`
    ${FooterFragment}
    ${GlobalFragment}
    ${SEO.fragments.entryTaxonomy}
    ${NavigationMenu.fragments.entry}
    ${PostCard.fragments.entry}
    ${TermLinks.fragments.entryRootTag}
    query GetTagPage(
      $id: ID!
      $databaseIdID: ID!
      $offset: Int!
      $postsPerPage: Int!
      $headerLocation: MenuLocationEnum
      $subMenuALocation: MenuLocationEnum
      $subMenuBLocation: MenuLocationEnum
    ) {
      node(id: $id) {
        ... on Tag {
          name
          description
          momTagIconsHtml
          momTagShowAd
          seo {
            ...TaxonomySEOFragment
          }
        }
      }
      posts(where: {tagIn: [$id], offsetPagination: {offset: $offset, size: $postsPerPage}}) {
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
        edges {
          node {
            ...${PostCard.fragments.key}
          }
        }
      }
      momSubNavHeader(objectDatabaseID: $databaseIdID)
      momAdTargeting(objectDatabaseID: $databaseIdID)
      momPopularPosts {
        ...${PostCard.fragments.key}
      }
      headlessSiteSettings {
        ...GlobalFragment
        ...FooterFragment
      }
      primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
        nodes {
          ...${NavigationMenu.fragments.key}
        }
      }
      popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
        ...${TermLinks.fragments.keyRootTag}
      }
    }
  `
};

ArchivePosts.variables = ({id, databaseId}, ctx, extra) => {
  const {paged} = extra; // Extra variables passed to the query from getStaticProps can be found here
  const isFirstPage = !paged || paged <= 1;
  const extraPostsCount = 1;

  return {
    id,
    databaseIdID: databaseId ?? 0,
    databaseIdInt: databaseId ?? 0,
    paged: paged ?? 1,
    offset: paged > 1 ? (paged - 1) * constants.POSTS_PER_PAGE : 0,
    postsPerPage: isFirstPage ? constants.POSTS_PER_PAGE + extraPostsCount : constants.POSTS_PER_PAGE,
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
  };
};
