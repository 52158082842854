import {gql} from '@apollo/client';
import {decode} from "@/lib/utils/helpers";
import dynamic from "next/dynamic";
import Link from "next/link";
import React from "react";

import * as constants from '@/lib/utils/constants';
import {BlogInfoFragment, FooterFragment, GlobalFragment} from '@/lib/fragments';
import Container from '@/components/Container/Container';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Heading from '@/components/Heading/Heading';
import Main from '@/components/Main/Main';
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu';
import PostsGrid from '@/components/PostsGrid/PostsGrid';
import SEO from '@/components/SEO/SEO';
import PostCard from "@/components/PostCard/PostCard";
import FeaturedPost from "@/components/FeaturedPost/FeaturedPost";
import InjectBanner from "@/components/InjectBanner/InjectBanner";
import HeroBanner from "@/components/HeroBanner/HeroBanner";
import PostsFeatured from "@/components/PostsFeatured/PostsFeatured";
import Statistics from "@/components/Statistics/Statistics";
import TermLinks from "@/components/TermLinks/TermLinks";
import Newsletter from "@/components/Newsletter/Newsletter";

// const Pagination = dynamic(() => import('@/components/Pagination/Pagination').then((mod) => mod.default));
const AdSlotHeaderDesktop = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.HeaderDesktop));
const AdSlotFooterDesktop = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterDesktop));
const AdSlotFooterSticky = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterSticky));
const AdSlotOneByOne = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OneByOne));
const AdSlotOutOfSpace = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OutOfSpace));
const AdSlotGridInline = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.GridInline));

export default function Component(props) {
  if (props.loading) {
    return <>Loading...</>;
  }

  const {data} = props;
  const {
    generalSettings: {title: siteTitle, description: siteDescription} = {},
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    momSubNavHeader: subNavHtml,
    posts: {pageInfo: {offsetPagination: {total}}} = {},
    nodeByUri: {seo} = {},
    popularTags = [],
  } = data || {};

  const {
    momFeaturedPost: featuredPost,
    momHomepagePosts: homepagePosts,
    momHomepageFeaturedVideos: homepageFeaturedVideos,
    momHomepageFeaturedSections: homepageFeaturedSections,
    momTrendingPosts: trendingPosts,
    momPopularPosts: popularPosts,
    momPromotedSidebarPosts: promotedSidebarPosts
  } = data;

  const fullTitle = siteTitle + ' - ' + decode(siteDescription);

  // Split first post from homepagePosts
  const firstHomepagePost = homepagePosts[0];
  const restOfHomepagePosts = homepagePosts.slice(1);

  return (<>
    <SEO seo={seo} title={fullTitle} description={siteDescription}/>

    <Header {...{primaryMenu, subNavHtml, subMenuA, subMenuB, siteSettings, popularTags}}/>

    <Main>
      <Heading level={'h1'} className={'sr-only'}>{fullTitle}</Heading>

      <FeaturedPost
        featuredPost={featuredPost}
        postsRight={trendingPosts}
        postsLeft={promotedSidebarPosts}
      />

      <InjectBanner
        settings={siteSettings}
        positionDesktop={3}
        positionMobile={2}
        className={`mom-section`}
        hasContainer={true}
      />

      <Container>
        <AdSlotHeaderDesktop/>
      </Container>

      <Container className={`mom-section`}>
        <div className={`flex flex-nowrap justify-between items-center mb-4 gap-5`}>
          <Heading level={`h2`} className={`mom-section-heading`}>The Latest News</Heading>
          <Link className={`mom-btn mom-btn--sm shrink-0`} href={constants.URI_BLOG}>See All</Link>
        </div>

        {/* TODO: bring this to component. Also being used on Archive pages */}
        <div className={`grid grid-rows-1 grid-cols-1 xl:grid-cols-2 gap-x-4 xl:gap-x-5`}>
          <PostCard {...firstHomepagePost} variant={'large'} className={`mb-5`}/>

          <PostsGrid
            posts={restOfHomepagePosts}
            gridClassName={`grid-cols-2`}
            gridItemClassName={`[&:nth-last-child(-n+2)]:mb-0`}
          />
        </div>
      </Container>

      {siteSettings?.hero?.image && (
        <Container className={`mom-section`}>
          <HeroBanner settings={siteSettings}/>
        </Container>
      )}

      <Container>
        <AdSlotGridInline adRowIndex={1} objectType={'home'}/>
      </Container>

      {homepageFeaturedSections && homepageFeaturedSections.map((section, index) => {
        return (
          <React.Fragment key={index}>
            <Container className={`mom-section`}>
              <PostsFeatured {...section}/>

              {(index === 1 || ((index % 2 === 0) && index > 2)) && (
                <AdSlotGridInline adRowIndex={index > 2 ? index + 1 : 3} objectType={'home'} />
              )}
            </Container>

            {index === 2 && (
              <>
                <Container className={`mom-section border-0 bg-navy-300 dark:bg-silver-300 rounded-[3rem]`}>
                  <Statistics/>
                </Container>
                <Container className={`mom-section py-0 border-t-0`}>
                  <AdSlotGridInline adRowIndex={5} objectType={'home'}/>
                </Container>
              </>
            )}
          </React.Fragment>
        );
      })}

      <Container className={`mom-section`}>
        <Newsletter showAwards={true} heading={'Want to join  our exclusive community?'} description={''} variant={'inline'}/>
      </Container>

      {homepageFeaturedVideos?.videos?.length && (
        <Container className={`mom-section`}>
          <PostsFeatured {...homepageFeaturedVideos} posts={homepageFeaturedVideos.videos}/>

          <AdSlotFooterDesktop adRowIndex={10} objectType={'home'}/>
        </Container>
      )}

      <Container className={`mom-section`} edgePadding={false}>
        <Heading className={`mom-subheading mx-4 xl:mx-0`} level={'h3'}>Popular Stories</Heading>
        <PostsGrid
          posts={popularPosts}
          className={`pl-4 xl:pl-container xl:-mx-container`}
          columnsPerRow={1}
          variant={'small'}
          gridClassName={`grid-cols-1`}
          scrollable={true}
        />
      </Container>
    </Main>

    <Footer {...{subMenuA, subMenuB, siteSettings}} className={''}/>

    <AdSlotFooterSticky objectType={'home'}/>
    <AdSlotOneByOne objectType={'home'}/>
    <AdSlotOutOfSpace objectType={'home'}/>
  </>);
}

Component.query = gql`
  ${BlogInfoFragment}
  ${FooterFragment}
  ${GlobalFragment}
  ${SEO.fragments.entryPostType}
  ${NavigationMenu.fragments.entry}
  ${FeaturedPost.fragments.entry}
  ${PostCard.fragments.entry}
  ${PostsFeatured.fragments.entry}
  ${PostsFeatured.fragments.entryVideo}
  ${TermLinks.fragments.entryRootTag}
  ${HeroBanner.fragments.entry}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $subMenuALocation: MenuLocationEnum
    $subMenuBLocation: MenuLocationEnum
  ) {
    posts {
      pageInfo {
        offsetPagination {
          total
        }
      }
    }
    nodeByUri(uri: "home") {
      ... on Page {
        seo {
          ...${SEO.fragments.keyPostType}
        }
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    momSubNavHeader(objectDatabaseID: $databaseId)
    momAdTargeting(objectDatabaseID: $databaseId)
    headlessSiteSettings {
      ...GlobalFragment
      ...FooterFragment
      ...${HeroBanner.fragments.key}
    }
    momFeaturedPost {
      ...${FeaturedPost.fragments.key}
    }
    momPromotedSidebarPosts {
      ...${PostCard.fragments.key}
    }
    momTrendingPosts {
      ...${PostCard.fragments.key}
    }
    momPopularPosts {
      ...${PostCard.fragments.key}
    }
    momHomepagePosts(first: 5) {
      ...${PostCard.fragments.key}
    }
    momHomepageFeaturedSections {
      ...${PostsFeatured.fragments.key}
    }
    momHomepageFeaturedVideos(first: 4) {
      ...${PostsFeatured.fragments.keyVideo}
    }
    primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
      ...${TermLinks.fragments.keyRootTag}
    }
  }
`;

Component.variables = ({databaseId}) => {
  return {
    databaseId,
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
  };
};
