import {gql} from "@apollo/client";
import Link from "next/link";
import PropTypes from "prop-types";
import classNames from "classnames";

import Heading from "@/components/Heading/Heading";
import PostsGrid from "@/components/PostsGrid/PostsGrid";
import PostCard from "@/components/PostCard/PostCard";
import { getRemovedHostContent } from "@/lib/utils/helpers";

const gridClassNames = {
  default: 'grid-cols-2 xl:grid-cols-4',
  large: 'grid-cols-2',
};

const gridItemClassName = {
  default: '[&:nth-last-child(-n+2)]:mb-0 lg:[&:nth-last-child(-n+4)]:mb-0',
  large: '[&:nth-last-child(-n+2)]:mb-0',
};

export default function PostsFeatured({link, title, posts = [], className}) {
  let variant = 'default';
  if (posts.length <= 2) {
    variant = 'large';
  }

  return (
    <>
      <div className={classNames(
        'flex flex-nowrap justify-between items-center mb-4 gap-5',
        className
      )}>
        <Heading level={`h2`} className={`mom-section-heading`}>{title}</Heading>
        {link && <Link className={`mom-btn mom-btn--sm shrink-0`} href={getRemovedHostContent(link)}>See All</Link>}
      </div>

      <PostsGrid
        posts={posts}
        gridClassName={gridClassNames[variant]}
        gridItemClassName={gridItemClassName[variant]}
        variant={variant}
      />
    </>
  );
}

PostsFeatured.fragments = {
  key: 'MomPostsFeatured',
  entry: gql`
    ${PostCard.fragments.entry}
    fragment MomPostsFeatured on MomFeatureCategory {
      link
      title
      posts {
        ...${PostCard.fragments.key}
      }
    }
  `,
  keyVideo: 'MomPostsFeaturedVideo',
  entryVideo: gql`
    ${PostCard.fragments.entryVideo}
    fragment MomPostsFeaturedVideo on MomFeatureVideos {
      link
      title
      videos {
        ...${PostCard.fragments.keyVideo}
      }
    }
  `
};

PostsFeatured.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
};
