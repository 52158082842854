import category from './category';
import tag from './tag';
import frontPage from './front-page';
import archivePost from './archive-post';
import pageContact from './page-contact';
import pageNews from './page-news';
import author from './author';
import page from './page';
import single from './single';
import singleVideo from './single-video';
import archiveVideo from './archive-video';
export default {
  'front-page': frontPage,
  'page-contact': pageContact,
  'page-news': pageNews,
  'archive-post': archivePost,
  'archive-video': archiveVideo,
  home: archivePost,
  author,
  category,
  tag,
  page,
  single,
  'single-video': singleVideo,
};
