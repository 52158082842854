import {gql} from "@apollo/client";
import Link from "next/link";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import {decode, getStyledTitle} from "@/lib/utils/helpers";
import TermLinks from "@/components/TermLinks/TermLinks";
import Heading from "@/components/Heading/Heading";
import FeaturedImage from "@/components/FeaturedImage/FeaturedImage";
import PostsGrid from "@/components/PostsGrid/PostsGrid";
import Container from "@/components/Container/Container";
import classNames from "classnames";

export default function FeaturedPost(
  {
    featuredPost,
    postsRight = [],
    postsLeft = [],
  }
) {
  return (
    <Container edgePadding={false} className={`grid grid-cols-12 xl:gap-5 xl:py-10`}>
      <div className={classNames(
        'py-8 md:py-10 xl:py-0 mx-4 xl:mx-0',
        'col-span-12 order-2 md:order-1 xl:col-span-9 grid grid-cols-12 gap-5'
      )}>
        <div className={classNames('col-span-12 order-2 md:col-span-4 xl:order-1')}>
          <PostsGrid
            posts={postsLeft}
            columnsPerRow={1}
            isFeatured={true}
            gridClassName={`grid-cols-2 md:grid-cols-1`}
          />
        </div>

        <div className={classNames(
          'text-center',
          'col-span-12 order-1 md:col-span-8 xl:order-2 group/FeaturedPost',
        )}>
          <Link href={featuredPost.uri} className={`mb-6 block transition-opacity group-hover/FeaturedPost:opacity-90`}>
            <FeaturedImage

              width={640}
              height={475}
              image={featuredPost.featuredImage?.node}
              priority={true}
              sizes={'(max-width: 768px) 100vw, 50vw'}
            />
          </Link>

          <div className={`lg:px-7`}>
            <TermLinks
              className={`mb-1 lg:mb-2`}
              terms={featuredPost?.categories}
              primaryOnly={true}
              variant={'small'}
            />

            <Heading level={'h2'} className={`text-h1 lg:text-[3.375rem]/[1.11] lg:mb-2`}>
              <Link href={featuredPost.uri} className={`no-underline`}>
                {getStyledTitle({title: decode(featuredPost.title)})}
              </Link>
            </Heading>

            {featuredPost?.['excerpt'] ? parse(featuredPost['excerpt']) : ''}
          </div>
        </div>
      </div>

      <div className={classNames(
        'pb-4 xl:pb-0',
        'col-span-12 order-1 md:order-2 xl:col-span-3',
      )}>
        <Heading className={`mom-subheading mx-4 xl:mx-0 pt-2 md:pt-4 xl:pt-0 xl:-mt-[7px]`} level={'h3'}>
          Trending Stories
        </Heading>
        <PostsGrid
          posts={postsRight}
          className={`pl-4 xl:pl-0`}
          columnsPerRow={1}
          variant={'small'}
          gridClassName={`grid-cols-1`}
          scrollable={true}
          scrollableOffXl={true}
        />
      </div>
    </Container>
  );
}

FeaturedPost.fragments = {
  key: 'FeaturedPostFragment',
  entry: gql`
    ${TermLinks.fragments.entryCategory}
    ${FeaturedImage.fragments.entry}
    fragment FeaturedPostFragment on Post {
      featuredImage {
        ...${FeaturedImage.fragments.key}
      }
      title
      excerpt
      uri
      databaseId
      categories {
        ...${TermLinks.fragments.keyCategory}
      }
    }
  `
};

FeaturedPost.propTypes = {
  featuredPost: PropTypes.object.isRequired,
  postsLeft: PropTypes.arrayOf(PropTypes.object),
  postsRight: PropTypes.arrayOf(PropTypes.object),
};
