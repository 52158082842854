/* MENUS */
export const MENU_PRIMARY_LOCATION = 'PRIMARY';
export const MENU_FOOTER_LEFT_LOCATION = 'FOOTER_LEFT';
export const MENU_FOOTER_RIGHT_LOCATION = 'FOOTER_RIGHT';

/* URI */
export const URI_BLOG = '/blog';
export const URI_ABOUT_US = '/about';
export const URI_TEAM = '/team';
export const URI_HOW_WE_TEST = '/how-we-test';
export const URI_VIDEOS = '/videos';
/* API */
export const API_FORM_NEWSLETTER = '/api/forms/newsletter';
export const API_FORM_CONTACT = '/api/forms/contact';

/* SELECTORS */
export const MAIN_CONTENT_ID = 'main-content';
export const PRIMARY_NAV_ID = 'primary-nav';
export const PRIMARY_NAV_TOGGLE_ID = 'primary-nav-toggle';

/* COOKIES */
export const COOKIE_NEWSLETTER_POPUP_SHOWN = 'mom_newsletter_popup_shown';
export const COOKIE_NEWSLETTER_POPUP_EXPIRY_DAYS = 7;

/* POSTS */
export const POSTS_PER_PAGE = 24;
export const BUTTON_VARIANTS = ['default', 'fill', 'icon'];
export const SIZES = ['default', 'small'];
export const CARD_SIZE = ['default', 'small', 'large'];
export const EXCLUDED_TERMS = ['nooutbrain', 'nogumgum', 'nounruly', 'notrending', 'norelated'];
export const NOINDEX_POSTS_IN_TAGS = ['feel-good-friday', 'the-wind-up', 'staff-favourites', 'sneaker-news', 'monday-munchies', 'most-wanted'];
export const NOINDEX_POSTS_IN_CATEGORY = ['sponsored'];
export const LAST_ROW_AD_INDEX = 5;
export const DEFAULT_FOOTER_DESKTOP_POST_POSITION_INDEX = 0;
