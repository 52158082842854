import classNames from 'classnames';
import {gql} from "@apollo/client";
import Link from "next/link";
import Image from 'next/image';

import Container from "@/components/Container/Container";

const MediaWrapper = ({link, children, hasContainer, className}) => {
  if (link) {
    if (hasContainer) {
      return (
        <Container className={className}>
          <Link href={link} className={`block`}>
            {children}
          </Link>
        </Container>
      );
    }

    return (
      <Link href={link} className={`block ${className}`}>
        {children}
      </Link>
    );
  }

  if (hasContainer) {
    return (
      <Container className={className}>
        {children}
      </Container>
    );
  }

  return children;
};

export default function InjectBanner(
  {
    className,
    settings,
    hasContainer = false,
    positionDesktop = 1,
    positionMobile = 1,
    style,
    preloadImage = true
  }
) {
  const {momBanners, momBannerMobilePositions, momBannerDesktopPositions} = settings || {};
  let bannersDesktop, bannersMobile;

  if (momBannerDesktopPositions?.[`position${positionDesktop}`]) {
    bannersDesktop = momBanners.filter(banner => {
      return banner.position.includes(positionDesktop + '') && banner.device.includes('desktop');
    });
  }

  if (momBannerMobilePositions?.[`position${positionMobile}`]) {
    bannersMobile = momBanners.filter(banner => {
      return banner.position.includes(positionMobile + '') && banner.device.includes('mobile');
    });
  }

  const desktopElement = bannersDesktop?.[0];
  const mobileElement = bannersMobile?.[0];
  const desktopImage = desktopElement?.image?.node;
  const mobileImage = mobileElement?.image?.node;

  return (
    <>
      {(desktopElement || mobileElement) && (
        <>
          {desktopElement && (
            <MediaWrapper
              link={desktopElement?.link}
              hasContainer={hasContainer}
              className={`${className} hidden md:block`}
            >
              <Image
                style={style}
                className={classNames(
                  {'max-w-none w-full hidden md:block': !hasContainer},
                  {'mx-auto': hasContainer}
                )}
                src={desktopImage?.sourceUrl}
                sizes={'100vw'}
                alt={desktopImage?.altText}
                width={desktopImage?.mediaDetails?.width}
                height={desktopImage?.mediaDetails?.height}
                priority={preloadImage}
              />
            </MediaWrapper>
          )}

          {mobileElement && (
            <MediaWrapper
              link={mobileElement?.link}
              hasContainer={hasContainer}
              className={`${className} md:hidden`}
            >
              <Image
                style={style}
                className={classNames(
                  {'max-w-none w-full md:hidden': !hasContainer},
                  {'mx-auto': hasContainer}
                )}
                src={mobileImage?.sourceUrl}
                sizes={'100vw'}
                alt={mobileImage?.altText}
                width={mobileImage?.mediaDetails?.width}
                height={mobileImage?.mediaDetails?.height}
                priority={preloadImage}
              />
            </MediaWrapper>
          )}
        </>
      )}
    </>
  );
};

InjectBanner.fragments = {
  key: 'InjectBannerFragment',
  entry: gql`
    fragment InjectBannerFragment on HeadlessSiteSettings {
      siteSettings {
        momBanners {
          position
          link
          device
          image {
            node {
              id
              sourceUrl
              altText
              mediaDetails {
                width
                height
              }
            }
          }
        }
        momBannerMobilePositions {
          position1
          position2
          position3
          position4
        }
        momBannerDesktopPositions {
          position1
          position2
          position3
          position4
        }
      }
    }
  `
};
