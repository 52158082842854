import {gql} from '@apollo/client';
import InjectBanner from "@/components/InjectBanner/InjectBanner";

export const GlobalFragment = gql`
  ${InjectBanner.fragments.entry}
  fragment GlobalFragment on HeadlessSiteSettings {
    ...${InjectBanner.fragments.key}
    siteSettings {
      facebookUrl
      twitterUrl
      instagramUrl
      youtubeUrl
      tiktokUrl
      blueskyUrl
      whatsappUrl
      googleNews
      linkedinUrl
      redditUrl
      pinterestUrl
    }
  }
`;

export const FooterFragment = gql`
  fragment FooterFragment on HeadlessSiteSettings {
    siteSettings {
      footerAffiliateDisclaimer
    }
  }
`;
