import {Suspense} from "react";
import {gql} from "@apollo/client";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "next/image";

import {getStyledTitle} from "@/lib/utils/helpers";
import * as constants from "@/lib/utils/constants";
import {LAST_ROW_AD_INDEX, URI_VIDEOS} from "@/lib/utils/constants";
import {FooterFragment, GlobalFragment} from "@/lib/fragments";
import PostAuthorFull from "@/components/PostAuthor/PostAuthorFull";
import Container from "@/components/Container/Container";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Main from "@/components/Main/Main";
import PostsGrid from "@/components/PostsGrid/PostsGrid";
import SEO from "@/components/SEO/SEO";
import NavigationMenu from "@/components/NavigationMenu/NavigationMenu";
import PostCard from "@/components/PostCard/PostCard";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import EntryHeader from '@/components/EntryHeader/EntryHeader';
import InjectBanner from "@/components/InjectBanner/InjectBanner";
import Loading from "@/components/Loading/Loading";
import Heading from "@/components/Heading/Heading";
import HeaderSubNav from "@/components/Header/HeaderSubNav";
import TermLinks from "@/components/TermLinks/TermLinks";
import Newsletter from "@/components/Newsletter/Newsletter";
import ArchivePosts from '@/components/ArchivePosts/ArchivePosts';
import HeroBanner from "@/components/HeroBanner/HeroBanner";
import PostsFeatured from "@/components/PostsFeatured/PostsFeatured";

const Pagination = dynamic(() => import('@/components//Pagination/Pagination').then((mod) => mod.default));
const AdSlotFooterDesktop = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterDesktop));
const AdSlotFooterSticky = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterSticky));
const AdSlotOneByOne = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OneByOne));
const AdSlotOutOfSpace = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OutOfSpace));

export default function Component(props) {
  const {data} = props;
  const {paged} = props?.__TEMPLATE_VARIABLES__ || props?.__PAGE_VARIABLES__ || {};
  const {uri, slug, __typename: archiveType} = props?.__SEED_NODE__ || {};

  let overrideDescription;
  let iconsHtml;
  let showAd = true;
  let isReviewer = false;
  let node;
  let trendingPosts;

  // Node selection based on archiveType (SEED NODE's __typename)
  node = data?.node;

  // Primary data
  /* const {name, description, seo} = node || {}; */
  const {name, description, seo} = {
    name: 'Videos',
    description: 'Watch the latest videos from Man of Many\'s YouTube channel, featuring product reviews, interviews, unboxings and more. Stay up to date with our video content.',
    seo: {
      title: 'Videos - Product reviews, interviews, unboxings | Man of Many',
      description: 'Watch the latest videos from Man of Many\'s YouTube channel, featuring product reviews, interviews, unboxings and more. Stay up to date with our video content.',
      breadcrumbs: [
        {
          url: '/',
          text: 'Home'
        },
        {
          url: URI_VIDEOS,
          text: 'Videos'
        }
      ],
    },
  };

  const {edges, pageInfo: {offsetPagination: {total, hasMore, hasPrevious}}} = data.videos || {};
  const postNodes = edges.map(({node}) => node);
  const {
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    momSubNavHeader: subNavHtml,
    momVideosFeaturedSeries: videosFeaturedSeries,
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    popularTags = [],
  } = data || {};

  const isFirstPage = paged <= 1;
  const isFirstFiveFeatured = isFirstPage;

  const firstFive = postNodes.slice(0, 5);
  const featuredPost = firstFive[0]; // TODO: replace this with better logic
  const restOfFirstFive = firstFive.slice(1);
  const restOfPosts = postNodes.slice(5);

  // Refine posts list, in case first page has more than constants.POSTS_PER_PAGE (eg: Author pages)
  if (isFirstPage && postNodes.length > constants.POSTS_PER_PAGE) {
    postNodes.pop();
  }

  return (
    <>
      <SEO {...{seo, title: 'The Latest Videos', paged, hasMore, hasPrevious}} url={uri}/>

      <Header {...{primaryMenu, subMenuA, subMenuB, siteSettings, popularTags}}/>

      <Main>
        <Suspense fallback={<Loading/>}>
          <Container>
            <InjectBanner
              settings={siteSettings}
              positionDesktop={4}
              positionMobile={3}
              style={{marginTop: '1rem'}}
            />
          </Container>

          <Container>
            <EntryHeader
              title={`${name}${!isFirstPage ? ` - Page ${paged}` : ''}`}
              description={overrideDescription || description}
            />
          </Container>

          <Container className={classNames(
            `mom-gap !pt-0`,
            {'mom-border border-t-2': !subNavHtml}
          )}>
            <Breadcrumbs list={seo?.breadcrumbs}/>

            {isFirstFiveFeatured && (
              <>
                <div className={`flex flex-nowrap justify-between items-center mb-4 gap-5`}>
                  <Heading level={`h2`} className={`mom-section-heading`}>
                    The Latest Videos
                  </Heading>
                </div>

                <div className={`grid grid-rows-1 grid-cols-1 xl:grid-cols-2 gap-x-4 xl:gap-x-5`}>
                  <PostCard {...featuredPost} className={`mb-5`}/>

                  <PostsGrid
                    posts={restOfFirstFive}
                    gridClassName={`grid-cols-2`}
                    gridItemClassName={`[&:nth-last-child(-n+2)]:mb-0`}
                  />
                </div>
              </>
            )}
          </Container>

          {(isFirstPage && videosFeaturedSeries && videosFeaturedSeries?.episodes?.length > 0) && (
            <Container className={classNames(
              {'mom-section pb-4': isFirstFiveFeatured},
            )}>
              <div className={classNames(
                'grid grid-cols-1 xl:grid-cols-12 gap-4 md:gap-5',
                '[&>div:first-child]:xl:order-last'
              )}>
                <div className="col-span-1 xl:col-span-6">
                  <figure className={`relative aspect-video`}>
                    <Image
                      src={videosFeaturedSeries?.image?.sourceUrl}
                      alt={videosFeaturedSeries?.image?.altText}
                      fill
                      className={`object-cover`}
                    />
                  </figure>
                </div>

                <div className="col-span-1 xl:col-span-6">
                  {videosFeaturedSeries?.heading && <Heading level={`h2`} className={`md:text-h1 mb-3.5`}>{getStyledTitle({title: videosFeaturedSeries.heading})}</Heading>}
                  {videosFeaturedSeries?.textContent && <p className={`mb-4`}>{videosFeaturedSeries?.textContent}</p>}
                </div>
              </div>

              <Heading level={`h2`} className={`mom-section-heading mb-4 mt-8`}>Episodes</Heading>
              <PostsGrid
                posts={videosFeaturedSeries?.episodes}
                variant={`large`}
              />
            </Container>
          )}

          <Container className={classNames(
            {'mom-section pb-0': isFirstFiveFeatured},
          )}>
            {total > 0 && (
              <Heading level={`h2`} className={`mom-heading`}>
                {total} Videos Published
              </Heading>
            )}

            <PostsGrid
              posts={isFirstFiveFeatured ? restOfPosts : postNodes}
              prioritizeFirstRow={true}
              showAd={showAd}
              adObjectType={'archive'}
            />

            {Math.ceil(total / constants.POSTS_PER_PAGE) > 1 && (
              <Pagination {...{total, paged, hasMore, hasPrevious, uri}}/>
            )}

            <Container edgePadding={false} className={`mom-section pb-0`}>
              <Newsletter showAwards={true} heading={'Want to join  our exclusive community?'} description={''} variant={'inline'}/>
              <InjectBanner
                settings={siteSettings}
                positionDesktop={0}
                positionMobile={4}
                style={{marginTop: '1rem'}}
              />
            </Container>

            {showAd && (
              <AdSlotFooterDesktop
                adRowIndex={LAST_ROW_AD_INDEX} objectType={'archive'}
              />
            )}
          </Container>
        </Suspense>
      </Main>

      <Footer {...{subMenuA, subMenuB, siteSettings}}/>

      {showAd && (
        <>
          <AdSlotFooterSticky objectType={'archive'}/>
          <AdSlotOneByOne objectType={'archive'}/>
          <AdSlotOutOfSpace objectType={'archive'}/>
        </>
      )}
    </>
  );
}

Component.query = gql`
  ${FooterFragment}
  ${GlobalFragment}
  ${NavigationMenu.fragments.entry}
  ${PostCard.fragments.entryVideo}
  ${TermLinks.fragments.entryRootTag}
  query GetBlogPage(
    $databaseIdID: ID!
    $offset: Int!
    $postsPerPage: Int!
    $headerLocation: MenuLocationEnum
    $subMenuALocation: MenuLocationEnum
    $subMenuBLocation: MenuLocationEnum
  ) {
    videos(where: {offsetPagination: {offset: $offset, size: $postsPerPage}}) {
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
      edges {
        node {
          ...${PostCard.fragments.keyVideo}
        }
      }
    }
    momVideosFeaturedSeries {
      heading
      textContent
      image {
        id
        sourceUrl
        altText
        mediaDetails {
          width
          height
        }
      }
      episodes {
        ...${PostCard.fragments.keyVideo}
      }
    }
    momSubNavHeader(objectDatabaseID: $databaseIdID)
    momAdTargeting(objectDatabaseID: $databaseIdID)
    headlessSiteSettings {
      ...GlobalFragment
      ...FooterFragment
    }
    primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
      ...${TermLinks.fragments.keyRootTag}
    }
  }
`;

Component.variables = ({id, databaseId}, ctx, extra) => {
  const {paged} = extra; // Extra variables passed to the query from getStaticProps can be found here
  const isFirstPage = !paged || paged <= 1;

  return {
    id,
    databaseIdID: databaseId ?? 0,
    databaseIdInt: databaseId ?? 0,
    paged: paged ?? 1,
    offset: paged > 1 ? (paged - 1) * constants.POSTS_PER_PAGE : 0,
    postsPerPage: isFirstPage ? constants.POSTS_PER_PAGE + 1 : constants.POSTS_PER_PAGE,
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
  };
};
