import {getStyledTitle} from "@/lib/utils/helpers";
import Heading from "@/components/Heading/Heading";
import Link from "next/link";
import * as constants from '@/lib/utils/constants';

export default function Statistics() {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 xl:gap-x-5 px-6 lg:px-0 max-lg:pb-3">
      <div className="text-center xl:text-left mb-8 xl:mb-0">
        <Heading level={`h2`} className={`italic lg:font-normal text-h4 md:text-h1 mb-6 xl:mb-14`}>
          How We Test Products
        </Heading>
        
        <p className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'Hands-on  testing for authentic, real-world insights'})}</p>
        <p className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'Impartial and objective evaluations  from industry experts'})}</p>
        <p className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'Independent reviews  backed by a five-step assessment process'})}</p>
        <p className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'Verified, accurate information  you can trust'})}</p>

        <Link href={constants.URI_HOW_WE_TEST} className={`mom-btn mom-btn--fill mt-4 xl:mt-8`}>Learn more how we test</Link>
      </div>

      <div className={`text-center grid grid-cols-2 grid-rows-2 gap-x-6 gap-y-4 lg:gap-y-12 max-lg:leading-4`}>
        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            52+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Expert Contributors</p>
          <p>Ensuring diverse insights</p>
        </div>

        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            8,000+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>In-Depth Reviews</p>
          <p>Covering lifestyle, tech, and more</p>
        </div>

        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            12+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Years of Publishing</p>
          <p>Since 2012 over a decade of trusted product journalism</p>
        </div>

        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            94+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Industry Awards</p>
          <p>Recognising excellence in media and content</p>
        </div>
      </div>
    </div>
  );
};
