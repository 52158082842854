import {gql} from "@apollo/client";
import Link from 'next/link';
import classNames from 'classnames';
import PropTypes from "prop-types";

import Heading from "../Heading/Heading";
import FeaturedImage from "../FeaturedImage/FeaturedImage";
import TermLinks from "../TermLinks/TermLinks";
import {CARD_SIZE} from "@/lib/utils/constants";
import {getStyledTitle} from "@/lib/utils/helpers";
import ImgBadge from '@/lib/assets/images/badge.svg';

const imageDimensions = {
  default: {width: 308, height: 185},
  small: {width: 208, height: 208},
  large: {width: 635, height: 380},
};

export default function PostCard(
  {
    title,
    uri,
    categories,
    videoSeries,
    featuredImage,
    featuredImagePriority = false,
    className,
    momHasVideo,
    isFeatured = false, // True to replace term link with featured text
    momHasFeaturedBadge = false, // True to show featured badge
    variant = 'default',
  }
) {
  const imageSize = imageDimensions[variant] || imageDimensions.default;
  const isSmallVariant = variant === 'small';

  // Fallback to videoSeries if categories is empty
  const terms = categories;
  // const terms = videoSeries || categories;

  return (
    <div className={classNames(`group/PostCard`, className)}>
      <Link
        href={uri}
        className={classNames(
          'no-underline block relative',
          {'group-hover/PostCard:opacity-90 transition-opacity': !momHasVideo},
          {'mom-has-video': momHasVideo},
          {'float-left mr-4 size-[104px]': isSmallVariant},
          {'mb-4': !isSmallVariant},
        )}
        aria-label={title}
      >
        <FeaturedImage
          {...imageSize}
          priority={featuredImagePriority}
          image={featuredImage?.node}
          style={{aspectRatio: `${imageSize.width}/${imageSize.height}`}}
        />
        {/* {momHasFeaturedBadge && (
          <span className={classNames(
            `absolute top-0.5 left-0.5`,
          )}>
            <ImgBadge className={`relative z-[2] lucide w-7 h-7`} />
          </span>
        )} */}
      </Link>

      <div className={classNames({
        'mb-2 md:mb-4': !isSmallVariant,
        'mb-1': isSmallVariant
      })}
      >
        {/* {!isFeatured
          ? <TermLinks terms={categories} primaryOnly={true} variant={variant}/>
          : <span className={`mom-pill`}>FEATURED POST</span>
        } */}
        <TermLinks terms={terms} primaryOnly={true} variant={variant}/>
      </div>

      <Heading level={'h3'} className={classNames(
        'text-sm',
        {'font-sans line-clamp-4': isSmallVariant},
        {'md:text-h4 mb-0': !isSmallVariant},
      )}>
        <Link href={uri} title={title} className={
          `no-underline group-hover/PostCard:text-navy dark:group-hover/PostCard:text-silver transition-colors`
          }>{getStyledTitle({title})}
        </Link>
      </Heading>
    </div>
  );
};

PostCard.fragments = {
  key: 'PostCardFragment',
  entry: gql`
    ${FeaturedImage.fragments.entry}
    ${TermLinks.fragments.entryCategory}
    fragment PostCardFragment on Post {
      featuredImage {
        ...${FeaturedImage.fragments.key}
      }
      databaseId
      title
      uri
      momHasVideo
      momHasFeaturedBadge
      categories {
        ...${TermLinks.fragments.keyCategory}
      }
    }
  `,
  keyVideo: 'VideoCardFragment',
  entryVideo: gql`
    ${FeaturedImage.fragments.entry}
    ${TermLinks.fragments.entryVideo}
    fragment VideoCardFragment on Video {
      featuredImage {
        ...${FeaturedImage.fragments.key}
      }
      databaseId
      title
      uri
      momHasVideo
      videoSeries {
        ...${TermLinks.fragments.keyVideo}
      }
    }
  `
};

PostCard.propTypes = {
  categories: PropTypes.object,
  className: PropTypes.string,
  featuredImage: PropTypes.object,
  featuredImagePriority: PropTypes.bool,
  isFeatured: PropTypes.bool,
  momHasFeaturedBadge: PropTypes.bool,
  momHasVideo: PropTypes.bool,
  variant: PropTypes.oneOf(CARD_SIZE),
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};
