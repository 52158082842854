import classNames from 'classnames';
import {gql} from "@apollo/client";
import Link from 'next/link';
import {flatListToHierarchical} from '@faustwp/core';
import {LazyMotion, m} from "framer-motion";
import {useRouter} from 'next/router';
import {useHoverIntent} from "@/lib/utils/hooks";
import IconChevronRight from '@/lib/assets/images/chev-right.svg';
import {useState} from "react";
import {twMerge} from "tailwind-merge";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: {stiffness: 1000, velocity: -100}
    }
  },
  closed: {
    y: 5,
    opacity: 0,
    transition: {
      y: {stiffness: 1000}
    }
  }
};

const navClasses = {
  dropdown: {
    menu: 'flex flex-nowrap items-center justify-start lg:justify-center lg:gap-1 whitespace-nowrap',
    submenu: 'hidden lg:block lg:opacity-0 lg:invisible absolute top-full left-0 w-fit min-w-full transition-all bg-white dark:bg-black z-10 text-left shadow-lg pb-2',
    submenuOpen: '',
    listItemOpen: 'lg:[&>ul]:!opacity-100 lg:[&>ul]:!visible',
    listItem: 'relative',
    anyLink: 'px-2 lg:px-4 py-2 block hover:text-navy dark:hover:text-silver',
    parentLink: 'font-bold hover:text-navy dark:hover:text-silver',
    activeLink: 'text-navy dark:text-silver hover:text-navy-500 dark:hover:text-silver-100',
  },
  toggle: {
    menu: '',
    submenu: '!px-4 overflow-hidden max-h-0 transition-all duration-500 [&>li:first-child]:mt-4 font-normal',
    submenuOpen: '!max-h-[500px]',
    listItemOpen: '',
    listItem: 'py-3 mb-0 mom-border border-b-2 grid grid-cols-[1fr_auto] [&:nth-child(3)]:col-span-2 [&_li]:border-b-0 [&_li]:italic',
    anyLink: '',
    parentLink: '',
    activeLink: 'text-navy dark:text-silver',
  },
  default: {
    menu: '',
    submenu: '',
    submenuOpen: '',
    listItemOpen: '',
    listItem: '',
    anyLink: '',
    parentLink: '',
    activeLink: 'text-navy dark:text-silver',
  }
};

function MenuItems(
  {
    items,
    isSubmenu = false,
    variant = 'default',
    className,
    activeSubmenu,
    setActiveSubmenu
  }
) {
  const router = useRouter();
  const isVariantDropdown = variant === 'dropdown';
  const isToggleVariant = variant === 'toggle';

  return (
    <LazyMotion features={() => import("framer-motion").then((mod) => mod.domAnimation)}>
      <ul
        className={classNames( // Do not use cx here, as it will be used as :global for css module
          'ml-0 pl-0',
          {'menu': !isSubmenu}, // TODO: convert to Tailwind based on variant
          {'sub-menu': isSubmenu}, // TODO: convert to Tailwind based on variant
          {[navClasses[variant].menu]: !isSubmenu},
          {[navClasses[variant].submenu]: isSubmenu},
          className
        )}
      >
        {/* {(isToggleVariant && isSubmenu) && (
          <li>
            <button
              className={classNames(
                `pr-12 text-left mt-4`,
              )}
              onClick={() => setActiveSubmenu(null)}
            >
              <IconChevronRight className={`h-4 w-3 rotate-180`}/>
            </button>
          </li>
        )} */}
        {items.map((item) => {
          const {id, path, label, children, cssClasses} = item;

          // @TODO - Remove guard clause after ghost menu items are no longer appended to array.
          if (!item.hasOwnProperty('__typename')) {
            return null;
          }

          const hoverIntent = useHoverIntent();
          const {isOpen, onMouseEnter, onMouseLeave} = hoverIntent;
          const isActive = router.asPath.startsWith(path);
          const isSubmenuToggled = activeSubmenu === id;

          return (
            <m.li
              key={id}
              variants={variants}
              onMouseEnter={isVariantDropdown ? onMouseEnter : undefined}
              onMouseLeave={isVariantDropdown ? onMouseLeave : undefined}
              className={twMerge(classNames(
                'menu-item', // TODO: to Tailwind
                {'menu-item-has-children': children.length > 0}, // TODO: to Tailwind
                'list-none',
                navClasses[variant].listItem,
                {[navClasses[variant].listItemOpen]: (isOpen && isVariantDropdown)},
                cssClasses
              ))}
            >
              <Link
                className={twMerge(classNames(
                  `no-underline block w-full hover:text-navy dark:hover:text-silver transition-colors`,
                  navClasses[variant].anyLink,
                  {[navClasses[variant].parentLink]: !isSubmenu},
                  {[navClasses[variant].activeLink]: isActive},
                ))}
                href={path ?? ''}
                prefetch={false}
              >
                {label ?? ''}
              </Link>

              {(isToggleVariant && !isSubmenu) && (
                <button
                  className={classNames(
                    `pl-12 text-right`,
                  )}
                  onClick={() => setActiveSubmenu(isSubmenuToggled ? null : id)}
                >
                  <IconChevronRight className={classNames(
                    `h-4 w-3 transition-transform`,
                    {'rotate-180': isSubmenuToggled},
                    {'rotate-90': !isSubmenuToggled}
                  )}/>
                </button>
              )}

              {children.length ? (
                <MenuItems
                  items={children}
                  isSubmenu={true}
                  variant={variant}
                  activeSubmenu={activeSubmenu}
                  setActiveSubmenu={setActiveSubmenu}
                  className={classNames(
                    {[navClasses[variant].submenuOpen]: isSubmenuToggled},
                  )}
                />
              ) : null}
            </m.li>
          );
        })}
      </ul>
    </LazyMotion>
  );
}

export default function NavigationMenu(
  {
    menuItems,
    className,
    variant = 'default',
  }
) {
  if (!menuItems) {
    return null;
  }

  // Based on https://www.wpgraphql.com/docs/menus/#hierarchical-data
  const hierarchicalMenuItems = flatListToHierarchical(menuItems);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  return (
    <nav
      className={classNames(className)}
      role="navigation"
      aria-label={`${menuItems[0]?.menu?.node?.name} menu`}>
      <MenuItems
        items={hierarchicalMenuItems}
        variant={variant}
        activeSubmenu={activeSubmenu}
        setActiveSubmenu={setActiveSubmenu}
      />
    </nav>
  );
}

NavigationMenu.fragments = {
  key: 'NavigationMenuItemFragment',
  entry: gql`
    fragment NavigationMenuItemFragment on MenuItem {
      id
      path
      label
      parentId
      cssClasses
      menu {
        node {
          name
        }
      }
    }
  `,
};
