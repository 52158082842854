import classNames from 'classnames';
import PropTypes from "prop-types";
import Link from "next/link";

import {decode} from "@/lib/utils/helpers";

export default function Breadcrumbs({list, className, hideLast = false}) {
  if (hideLast && list?.length > 0) {
    list = list.slice(0, -1);
  }

  return (
    <div className={classNames('text-[0.8125rem] py-3 text-center lg:text-left', className)}>
      {list?.map((item, index) => {
        return (
          <span key={index}>
            <Link href={item.url} prefetch={false} className={`last:no-underline`}>{decode(item.text)}</Link>
            {index < list.length - 1 && <span className={classNames('inline-block mx-1')}>{`/`}</span>}
          </span>
        );
      })}
    </div>
  );
}

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.any
  })).isRequired,
  hideLast: PropTypes.bool
};
